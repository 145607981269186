@use '../../styleguide/vars.scss';

.root {
  span {
    display: none;

    @media screen and (min-width: vars.$extra-large) {
      display: initial;
    }
  }
}
.inputFile {
  display: none !important;
}